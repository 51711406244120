.text {
  color: var(--mb-color-text-primary);
  font-weight: bold;
}

.link {
  cursor: pointer;

  &:hover {
    color: var(--mb-color-brand);
  }

  &:focus-visible {
    outline: 0;
  }
}
